<template>
  <div class="mt-5">
    <template v-if="!!id">
      <BaseTextarea
          label="Descripción de plan de acción"
          v-model="formData.description"
          :disabled="true"
      />
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseDownload
            v-for="(option, index) in formData.files"
            :value="option.file_name"
            :key="index"
            :url_download="option.file"
        />
      </div>
    </template>
    <TracingForm
        class="mt-5"
        :plan-id="id"
        :recommendation-id="recommendationId"
        :tracings="formData.tracings"
        @on-success="onSuccess"
        @on-cancel="closeModal"
    />
    <AdvanceReportForm
        class="mt-5"
        :plan-id="id"
        :recommendation-id="recommendationId"
        :reports="formData.progress_reports"
        @on-success="onSuccess"
    />
  </div>
</template>

<script>
import TracingForm from "@/components/Dashboard/FCO/Recommendations/TracingForm";
import AdvanceReportForm from "@/components/Dashboard/FCO/Recommendations/AdvanceReportForm";
export default {
  name: "ActionPlanForm",
  components: {
    AdvanceReportForm,
    TracingForm
  },
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    recommendationId: {
      type: [String, Number],
      required: true,
    },
    formData: {
      type: Object,
      default: () => ({
        description: '',
        files: [],
        tracings: [],
        progress_reports: [],
      })
    }
  },
  methods: {
    onSuccess(data) {
      if (data) {
        this.formData.tracings.push(data);
        this.$emit("on-success", true);
      }
    },
    closeModal() {
      this.$emit("on-cancel", true);
    }
  }
}
</script>

<style scoped>

</style>