<template>
  <div class="w-full">
    <TotalComplaints class="ml-0" :total="count" title="Total" body="recomendaciones registradas" v-if="count > 0" />
    <div style="margin-bottom: 16px" v-if="!flagError">
      <RecommendationsHeader :showButton="hasSelected" @toggle-modal="toggleModal" @search-term="onSearchTerm"
        :search="search" />
    </div>
    <div v-if="!loading && !flagError">
      <div class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white users">
        <a-table :columns="columns" rowKey="id" :data-source="data" :pagination="false">
          <span slot="actions" slot-scope="text, record"
            class="mr-4 text-sm font-medium text-right whitespace-nowrap flex">
            <TableActions @viewRecommendationDetails="viewRecommendationDetails" :record="record"
              @toggleModal="toggleModal" />
          </span>
        </a-table>
      </div>
      <div class="mt-4 flex justify-end">
        <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
          show-size-changer size="large" @showSizeChange="onShowSizeChange" />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <a-modal :visible="showModal" width="56rem" class="w-full" :footer="null">
      <template #closeIcon>
        <a-button icon="close" class="border-none text-3xl absolute right-0 bottom-4 mt-3" @click="toggleModal()" />
      </template>
      <template #title>
        <div class="relative">
          <h1 class="text-center text-2xl text-blue-dark font-bold">
            Seguimiento recomendación
          </h1>
        </div>
      </template>
      <div class="max-w-4xl justify-center items-center">
        <a-table class="border rounded-xl my-6" :columns="modalColumns" rowKey="id" :data-source="action_plans"
          :pagination="false" :scroll="{ x: 0, y: 300 }">
          >
          <span slot="actions" slot-scope="text, record"
            class="mr-4 text-sm font-medium text-right whitespace-nowrap flex">
            <a-button icon="right" size="small" @click="toggleModalAction(record, 'Planes de Acción')"></a-button>
          </span>
        </a-table>
        <a-button type="primary" shape="round" class="my-6 text-lg" @click="toggleModalConfirmation()">Cierre de
          seguimiento a la recomendación</a-button>
      </div>
    </a-modal>
    <Modal v-if="showModalAction" size="3xl">
      <div class="relative">
        <h1 class="text-center text-2xl text-blue-dark font-bold">
          {{ actionModal.title }}
        </h1>
        <a-button icon="close" class="border-none text-3xl absolute right-0 bottom-4 mt-3" @click="toggleModalAction" />
      </div>
      <div class="overflow-scroll max-h-96">
        <ActionPlanForm :id="actionModal.data.id" :recommendation-id="actionModal.data.recommendation"
          :form-data="actionModal.data" @on-cancel="closeModals" @on-success="toggleModalAction" />
      </div>
    </Modal>
    <Modal v-if="showModalConfirmation" size="3xl">
      <div class="relative">
        <h1 class="text-center text-xl text-blue-dark font-bold mt-12">
          Seguimiento recomendación
        </h1>
        <a-button icon="close" class="border-none text-3xl absolute right-0 bottom-12 mt-3"
          @click="toggleModalConfirmation()"></a-button>
      </div>
      <p class="text-center text-3xl font-bold my-12">
        Cierre de seguimiento a la recomendación <br />¿La entidad cumple
        completamente el plan de acción propuesto?
      </p>

      <div class="flex gap-6 justify-center mt-5 mb-12">
        <a-button class="w-1/3" type="primary" shape="round" ghost @click="toggleModalConfirmation()">NO</a-button>
        <a-button class="w-1/3" type="primary" shape="round" @click="toggleModalClose">SÍ</a-button>
      </div>
    </Modal>
    <Modal v-if="showModalActionClose" size="3xl">
      <div class="relative">
        <h1 class="text-center text-2xl text-blue-dark font-bold">
          Cierre de proceso de seguimiento a la recomendación
        </h1>
        <a-button icon="close" class="border-none text-3xl absolute right-0 bottom-4 mt-3" @click="toggleModalClose" />
      </div>
      <div class="overflow-scroll max-h-96">
        <close-form :recommendation-id="selectedData.id" @on-cancel="toggleModalClose"
          @on-success="closeRecommendation" />
      </div>
    </Modal>
    <div class="flex flex-col gap-1 max-h-96 overflow-scroll" v-if="flagError">
      <p class="text-gray-500 font-bold text-left">
        Módulo no habilitado para EV. Solo usuarios DCF.
      </p>
    </div>
  </div>
</template>
<script>
import RecommendationsHeader from "@/components/Dashboard/FCO/Recommendations/RecommendationsHeader";
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";
import ReportStatusLabel from "@/components/Dashboard/FCO/FCOStatusLabel.vue";
import ActionPlanForm from "@/components/Dashboard/FCO/Recommendations/ActionPlanForm";
import CloseForm from "@/components/Dashboard/FCO/Recommendations/CloseForm";
import TotalComplaints from "@/components/Dashboard/Complaints/TotalComplaints.vue";
import TableActions from "@/components/Dashboard/FCO/Recommendations/tableActions/TableActions.vue";

const modalColumns = [
  {
    title: "Fecha de subida",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Concepto",
    dataIndex: "concept",
    key: "concept",
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];
const columns = [
  {
    title: "Fecha de creción",
    dataIndex: "creation_date",
    key: "creation_date",
  },
  {
    title: "Entidad",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Responsable",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    CloseForm,
    ActionPlanForm,
    ModalConfirmation,
    RecommendationsHeader,
    TotalComplaints,
    TableActions,
  },
  data() {
    return {
      data: [],
      action_plans: [],
      tracings: [],
      progress_reports: [],
      columns,
      modalColumns,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      current: null,
      showModal: false,
      showModalAction: false,
      showModalConfirmation: false,
      showModalActionClose: false,
      hasSelected: false,
      searchTerm: "",
      page: 1,
      pageSize: 20,
      count: 0,
      query: "",
      search: null,
      pageSizeOptions: ["20", "40", "60", "80", "100"],
      actionModal: {
        title: null,
        data: {},
      },
      selectedData: {},
      flagError: false,
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 20;
    }
    this.start();
  },

  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({
          query: {
            page: this.page,
            search: this.searchTerm,
            pageSize: this.pageSize,
          },
        });
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: {
            page: this.page,
            search: this.searchTerm,
            pageSize: this.pageSize,
          },
        });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
  methods: {
    onSearchTerm(value) {
      this.showToast("info", "Buscando resultados...");
      this.searchTerm = value;
      this.search = this.searchTerm.search;
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.$router.replace({
          query: {
            page: this.page,
            search:
              value.user?.trim() /*, reason: value.reason, sfc_code: value.sfc_code, company_type: value.company_type, company: value.company*/,
          },
        });
        this.start();
      }
      this.page = 1;
    },
    async start() {
      this.flagError = false;
      if (![1, 2].includes(this.$store.state.session.user?.id_company?.id)) {
        this.flagError = true;
        return;
      }

      if (this.loading) return;
      let query = `?page=${this.page}&page_size=${this.pageSize}`;
      if (this.searchTerm) {
        let query_params = Object.keys(this.searchTerm)
          .map((key) => {
            if (this.searchTerm[key] && this.searchTerm[key] !== "") {
              return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(this.searchTerm[key])
              );
            }
          })
          .filter((data) => !!data)
          .join("&");
        query += query_params ? `&${query_params}` : "";
      }

      this.loading = true;
      let { error, data } = await this.$api.getRecommendations(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error consultando la lista de informes.");
      }

      if (data) {
        this.data = data.results.map((item) => {
          return {
            ...item,
            state: <ReportStatusLabel status={item.status_name} />,
            creation_date: item.created_at,
            user: item.full_name,
            company: item?.company,
            id_company: item?.company_id,
            sfc_code: item.sfc_code,
          };
        });
        this.count = data.count;
      }
    },

    viewRecommendationDetails(recommendation) {
      this.$router.push({
        path: "recommendations/view",
        query: {
          recommendation_code: recommendation.id,
          sfc_code: recommendation.sfc_code,
          company_type: recommendation.company_type,
          id_company: recommendation.id_company,
        },
      });
    },
    toggleModal(data) {
      this.selectedData = {};
      if (data) {
        this.selectedData = data;
        this.action_plans = data?.action_plans?.map((source) => {
          return {
            ...source,
            concept: `Plan de acción ${source.id}`,
          };
        });
        this.tracings = data?.action_plans?.tracings?.map((source) => {
          return {
            ...source,
            concept: `Seguimiento ${source.id}`,
          };
        });
        this.progress_reports = data?.action_plans?.progress_reports?.map(
          (source) => {
            return {
              ...source,
              concept: `Reporte de progreso ${source.id}`,
            };
          }
        );
      } else {
        this.action_plans = [];
        this.tracings = [];
        this.progress_reports = [];
      }

      this.showModal = !this.showModal;
    },
    toggleModalAction(record, title) {
      this.actionModal = {
        title: title,
        data: record,
      };
      this.showModal = !this.showModal;
      this.showModalAction = !this.showModalAction;
    },
    toggleModalConfirmation() {
      this.showModal = !this.showModal;
      this.showModalConfirmation = !this.showModalConfirmation;
    },
    toggleModalClose() {
      this.showModalActionClose = !this.showModalActionClose;
    },
    closeModals() {
      this.showModalActionClose = false;
      this.showModalAction = false;
      this.showModalConfirmation = false;
      this.showModal = false;
    },
    closeRecommendation() {
      this.closeModals();
      this.start();
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.start();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => { },
      });
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
  },
};
</script>
<style>
.users .ant-table-thead>tr>th {
  font-weight: bold;
  color: #6b7280;
}

/* .users .ant-table-tbody > tr > td {
  text-align: center;
}*/
</style>
