var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100"},[_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":function($event){return _vm.viewRecommendationDetails(_vm.record)}}},[_c('a-icon',{style:({
            fontSize: '20px',
        }),attrs:{"type":"eye","theme":"twoTone","two-tone-color":"#3366cc"}})],1),(_vm.record.status_name == 'Borrador')?_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":_vm.editRecommendation}},[_c('a-icon',{style:({
            fontSize: '20px',
        }),attrs:{"type":"edit","theme":"twoTone","two-tone-color":"#3366cc"}})],1):_vm._e(),(_vm.record.status_name == 'Seguimiento')?_c('button',{staticClass:"btnBasic ml-3",attrs:{"icon":"right","size":"small"},on:{"click":function($event){return _vm.toggleModal(_vm.record)}}}):_vm._e(),_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":_vm.downloadRecommendation}},[_c('img',{attrs:{"src":require("@/assets/icons/downloadBlue.svg")}})]),_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":_vm.changeCollapsed}},[_c('img',{class:_vm.collapsed ? 'rotateUp' : 'rotateDown',attrs:{"src":require("@/assets/icons/arrow-left-blue.svg")}})]),_c('button',{staticClass:"btnTableAction",style:({
        top: _vm.topPosition,
    }),on:{"click":_vm.goToCloseRecommendation}},[_vm._v(" Cerrar ")]),(_vm.collapsed)?_c('div',{staticClass:"cardCollapse scrollCustom pt-110"},_vm._l((_vm.actionPlans),function(actionPlan,index){return _c('div',{key:index},[_c('div',{staticClass:"w-80 border-0"},[_c('span',[_c('b',[_vm._v(_vm._s(actionPlan.name))])]),_c('span',[_vm._v("08/06/2023 10:58 AM")]),_vm._m(0,true)]),_c('div',{staticClass:"w-20 border-0"},[_c('div',{staticClass:"d-flex border-0"},[_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":function($event){return _vm.viewRecommendationDetails(_vm.record)}}},[_c('a-icon',{style:({
                            fontSize: '20px',
                        }),attrs:{"type":"eye","theme":"twoTone","two-tone-color":"#3366cc"}})],1),_c('button',{staticClass:"btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min",on:{"click":_vm.editRecommendation}},[_c('a-icon',{style:({
                            fontSize: '20px',
                        }),attrs:{"type":"edit","theme":"twoTone","two-tone-color":"#3366cc"}})],1),_c('button',{staticClass:"btnTableAction orange",on:{"click":function($event){return _vm.viewRecommendationTracking(_vm.record)}}},[_vm._v("Seguimiento")])])])])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progressContainer"},[_c('div',{staticClass:"progressBar"},[_c('span',[_vm._v("50%")])])])}]

export { render, staticRenderFns }