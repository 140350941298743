<template>
    <div class="d-flex w-100">
        <button class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min"
            @click="viewRecommendationDetails(record)">
            <a-icon type="eye" theme="twoTone" two-tone-color="#3366cc" :style="{
                fontSize: '20px',
            }" />
        </button>
        <button v-if="record.status_name == 'Borrador'" @click="editRecommendation"
            class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min">
            <a-icon type="edit" theme="twoTone" two-tone-color="#3366cc" :style="{
                fontSize: '20px',
            }" />
        </button>
        <button class="btnBasic ml-3" v-if="record.status_name == 'Seguimiento'" icon="right" size="small"
            @click="toggleModal(record)"></button>
        <button @click="downloadRecommendation"
            class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min">

            <img src="@/assets/icons/downloadBlue.svg" />
        </button>
        <button @click="changeCollapsed" class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min">
            <img src="@/assets/icons/arrow-left-blue.svg" :class="collapsed ? 'rotateUp' : 'rotateDown'" />
        </button>
        <button @click="goToCloseRecommendation" class="btnTableAction" :style="{
            top: topPosition,
        }">
            Cerrar
        </button>
        <div v-if="collapsed" class="cardCollapse scrollCustom pt-110">
            <div v-for="(actionPlan, index) in actionPlans" v-bind:key="index">
                <div class="w-80 border-0">
                    <span><b>{{ actionPlan.name }}</b></span>
                    <span>08/06/2023 10:58 AM</span>
                    <div class="progressContainer">
                        <div class="progressBar">
                            <span>50%</span>
                        </div>
                    </div>
                </div>
                <div class="w-20 border-0">
                    <div class="d-flex border-0">
                        <button class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min"
                            @click="viewRecommendationDetails(record)">
                            <a-icon type="eye" theme="twoTone" two-tone-color="#3366cc" :style="{
                                fontSize: '20px',
                            }" />
                        </button>
                        <button @click="editRecommendation"
                            class="btnBasic flex justify-center col-span-1 text-xs pr-2 text-blue h-min">
                            <a-icon type="edit" theme="twoTone" two-tone-color="#3366cc" :style="{
                                fontSize: '20px',
                            }" />
                        </button>
                        <button @click="viewRecommendationTracking(record)" class="btnTableAction orange">Seguimiento</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ["record"],
    data() {
        return {
            collapsed: false,
            topPosition: "0px",
            actionPlans: [
                { name: "hello1" },
                { name: "hello2" },
                { name: "hello3" },
                { name: "hello4" },
                { name: "hello5" },
                { name: "hello6" },
                { name: "hello6" },
                { name: "hello6" },
                { name: "hello6" },
            ],
        };
    },
    methods: {
        changeCollapsed(event) {
            const elemento = event.target;
            const rect = elemento.getBoundingClientRect();

            const posY = rect.top + window.scrollY;

            const antTableBody = document.querySelector(".ant-table-body");
            const rect2 = antTableBody.getBoundingClientRect();
            const posY2 = rect2.top + window.scrollY;

            this.topPosition = `${posY2 - posY}px`;
            console.log(`${posY2 - posY}px`);

            this.collapsed = !this.collapsed;
        },
        downloadRecommendation() {
            alert("download");
        },
        editRecommendation() {
            this.$router.push(
                `recommendations/edit/?recommendation_code=${this.record.id}&id_company=${this.record.id_company}`
            );
        },
        goToCloseRecommendation() {
            this.$router.push(
                `recommendations/closeRecommendation/?recommendation_code=${this.record.id}&id_company=${this.record.id_company}`
            );
        },
        viewRecommendationDetails(obj) {
            this.$emit("viewRecommendationDetails", obj);
        },
        viewRecommendationTracking(obj) {
            this.$emit("viewRecommendationTracking", obj);
        },
        toggleModal(obj) {
            this.$emit("toggleModal", obj);
        },
    },
    computed: {},
};
</script>
<style>
.pt-110 {
    padding-top: 110px;
}

.orange {
    background: #f59700 !important;
    color: white !important;
}

.ant-table-thead>tr>th {
    font-weight: bold;
    color: #6b7280;
}

.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    gap: 10px;
}

.w-100 {
    width: 100%;
}

.btnTableAction {
    padding: 5px 30px;
    border-radius: 5px;
    color: gray;
    transition: 0.5s;
    background: #e7e7e7;
}

.btnBasic {
    border: 1px solid #d6d9df;
    padding: 5px 7px;
    border-radius: 5px;
    width: 37px;
    height: 33px;
}

.btnTableAction:hover {
    color: white;
    transition: 0.5s;
    background: #004884;
}

.btnTableAction.active {
    color: white;
    background: #004884;
}

.cardCollapse {
    z-index: 10000;
    justify-content: center;
    position: absolute;
    border: 1px solid #d6d9df;
    border-left: 0px;
    margin-top: 350px;
    border-right: 0px;
    padding: 20px;
    left: 0;
    display: flex;
    gap: 5px;
    background: white;
    width: 100%;
    height: 250px;
    flex-wrap: wrap;
}

.cardCollapse>div {
    width: 100%;
}

.cardCollapse>div,
.cardCollapse>div>div {
    border: 1px solid #d6d9df;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 90px;
}

.w-80 {
    width: 80%;
}

.w-50 {
    width: 50%;
}

.w-20 {
    width: 20%;
}

.border-0 {
    border: 0px !important;
}

/* Estilos para el scroll vertical */
.scrollCustom {
    overflow-y: scroll;
    /* Habilita el scroll vertical solo cuando se añade esta clase */
}

.scrollCustom::-webkit-scrollbar {
    width: 10px;
}

.scrollCustom::-webkit-scrollbar-track {
    background-color: transparent;
}

.scrollCustom::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

.rotateUp {
    transform: rotate(270deg) scale(1.5);
}

.rotateDown {
    transform: rotate(90deg) scale(1.5);
}

.progressContainer {
    width: 250px;
    display: flex;
    align-items: start;
    border-radius: 15px;
    background: #e5fbf5;
    height: 30px;

}

.progressBar {
    background: #309d7e;
    border-radius: 15px;
    height: 30px;
    width: 50%;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
}

button {
    height: 33px;
    ;
}
</style>
